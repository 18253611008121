<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button
        :buttons="headerButtons"
        @onNewCourse="$router.push({name: 'courses.wizard', params: {type: 'course'}})"
      />
    </div>
    <div class="col-12">
      <course-datatable/>
    </div>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import CourseDatatable from "./datatables/CourseDatatable";

export default {
  name: "IndexCoursePage",
  components: {CourseDatatable, OctoHeaderButton},
  data() {
    return {
      headerButtons: [
        {
          label: 'add',
          onClick: 'onNewCourse'
        }
      ],
    }
  }
}
</script>

<style scoped>

</style>
